<template>
    <modal class="chefSettingsModel" :isShow="isShow">
        <div class="header">厨师设置 </div>
        <div class="modal-body">
            <div class="li-box">
                <div class="li-flex">
                    <span class="lable-text">厨师编码：</span>
                    <div class="from-input"><input type="text" v-model="USER_CODE" maxlength="24" /></div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">厨师名称：</span>
                    <div class="from-input"><input type="text" v-model="USER_NAME" maxlength="24" /></div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">归属分组：</span>
                    <div class="from-input">
                        <el-select v-model="USERGROUPID" placeholder="请选择分组" >
                            <el-option
                            v-for="item in userGroups"
                            :key="item.User_GroupID"
                            :label="item.User_GroupName"
                            :value="item.User_GroupID"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="li-flex">
                    <span class="lable-text">考勤卡号：</span>
                    <div class="from-input"><input type="text" v-model="ACCESS_CARDNUMBER" maxlength="32" /></div>
                    <div class="read-card" @click="readCard()">读卡</div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">取消</button>
            <button class="btn selected" @click="confirm()"> 确认</button>
        </div>
        
    </modal>
</template>

<script>
// 厨师设置
export default {
    name:'chefSettingsModel',
    props:{
        isShow:Boolean,
        data:{
            type:Object
        }
    },
    data(){
        return {
            //用户id
            User_ID:'',
            //编号
            USER_CODE:'',
            //名称
            USER_NAME:'',
            //分组id
            USERGROUPID:'',
            //卡号
            ACCESS_CARDNUMBER:'',
            userGroups:[]
        }
    },
    watch:{
        isShow:{
            handler(newVal){
                if(newVal){
                    this.loadData();
                    this.User_ID=this.data?.User_ID||"";
                    this.USER_CODE=this.data?.USER_CODE||"";
                    this.USER_NAME=this.data?.USER_NAME||"";
                    this.USERGROUPID=this.data?.USERGROUPID||"";
                    this.ACCESS_CARDNUMBER=this.data?.ACCESS_CARDNUMBER||"";
                }
            },
            immediate:true,
        },
    },
    mounted(){
    },
    methods:{
        //加载 用户分组
        loadData(){
            if(this.userGroups.length==0){
                this.$cacheData.UserGroups().then((d)=>{
                    this.userGroups=d;
                }).catch((e)=>{
                    this.$message.error('加载用户分组数据失败：'+e,);
                    console.log('加载用户分组数据失败：'+e);
                })
            }
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.ACCESS_CARDNUMBER=d.data.snr;
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        hide(){
            this.$emit("closeModel");
        },
        confirm(){
            this.$emit("confirm",{
                 User_ID:this.User_ID,
                 USER_CODE:this.USER_CODE.trim(),
                 USER_NAME:this.USER_NAME.trim(),
                 USERGROUPID:this.USERGROUPID,
                 ACCESS_CARDNUMBER:this.ACCESS_CARDNUMBER.trim()
            })
        }
    }
}
</script>

<style lang="scss">
@import './chefSettingsModel.scss'
</style>